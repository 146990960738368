const img = {
  headerLogo: require("./header-logo.png"),
  logo3D: require("./logo-3d-.png"),
  logoPlain: require("./logo-plain.png"),
  services1: require("./customer-support.png"),
  services2: require("./teaching.png"),
  services3: require("./contract.png"),
  services4: require("./provider.png"),
  footerLogo: require("./footer-logo.png"),
  qrCodde: require("./qr-code.jpg"),
  sample1: require("./sample1.png"),
  sample2: require("./sample2.png"),
  sample3: require("./sample3.png"),
  sample4: require("./sample4.png"),
  sample6: require("./sample6.png"),
  sample7: require("./sample7.png"),
  sample8A: require("./sample8-a.png"),
  sample8b: require("./sample8-b.png"),
  gmail: require("./gmail-icon.png"),
  facebook: require("./facebook-icon.png"),
  mobile: require("./mobile-icon.png"),
  team1: require("./team-1.png"),
  team2: require("./team-2.png"),
  team3: require("./team-3.png"),
  team4: require("./team-4.png"),
  banner2: require("./banner2.jpg"),
  esl1: require("./ESL 1.png"),
  esl2: require("./ESL 2.png"),
  esl3: require("./ESL 3.png"),
  esl4: require("./ESL 4.png"),
  personalLearning: require("./Personalized Learning.png"),
  expIns: require("./Experienced Instructors.png"),
  interactiveEngaging: require("./Interactive & Engaging.png"),
  globalOppur: require("./Global Opportunities.png"),
  beginAd: require("./Beginner to Advance.png"),
  specialCourse: require("./Specialized Course.png"),
  flexLearning: require("./Flexible Learning Options.png"),
};

export default img;
